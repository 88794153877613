import apiClient from "../Http/Http.service";

export class AppointmentsService {
  async getAppointments(filters: FilterAppointment) {
    const response = await apiClient.get(
      `/appointments/availables?program=${filters.programId}&appointmentType=${filters.appointmentTypeId}&doctor=${filters.doctorId}&date=${filters.date}`
    );
    return response.data;
  }

  async getAppointmentTypes() {
    const response = await apiClient.get("/masters/appointment-types");
    return response.data;
  }

  async scheduleAppointment(appointmentData: AppointmentData) {
    try {
      const response = await apiClient.post(
        "/appointments/schedule",
        appointmentData
      );
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }

  async cancelAppointment(data: any) {
    const response = await apiClient.post("/appointments/cancel", data);
    if (response.status == 500) {
      return response;
    }
    return response.data;
  }

  async blockAppointment(data: any) {
    const response = await apiClient.post("/appointments/bloquear", data);
    if (response.status == 500) {
      return response;
    }
    return response.data;
  }

  async unlockAppointment(data: any) {
    const response = await apiClient.post("/appointments/liberar", data);
    if (response.status == 500) {
      return response;
    }
    return response.data;
  }

  async getAppointmentsByPatient(patient: string) {
    const response = await apiClient.get(
      `/appointments/by-patient?identification=${patient}`
    );
    return response.data;
  }
}
