import { AppointmentsService } from "@/shared/services/appointments.service";
import { Utils } from "@/shared/utils/Utils";
import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";
import ModalTemplate from "@/components/ModalTemplate/ModalTemplate.vue";
import moment from "moment";
import vuetify from "@/plugins/vuetify";

@Component({
  name: "Appointments",
  components: { ModalTemplate },
  computed: {},
})
export default class Appointments extends Vue {
  @Inject() appointmentsService!: AppointmentsService;

  documentPatient: string = "";
  errorMessages: string[] = [];
  appointmentData = [];
  modalTextTemplate = "";
  modalHeaderTemplate = "";
  showConfirmCancel = false;
  appointmentToCancel: any = null;
  showConfirmCancel2 = false;

  async onChangePatientDocument(input: any) {
    if (input.key != "Enter") {
      return;
    }
    this.getAppointments();
  }

  async getAppointments() {
    this.appointmentData = [];
    const tempData = await this.appointmentsService.getAppointmentsByPatient(
      this.documentPatient
    );

    if (tempData.length == 0) {
      this.$store.dispatch("setAlert", {
        text: "No se encontraron datos para el documento ingresado.",
        type: "info",
      });
      return;
    }

    this.appointmentData = tempData.map((e: any) => {
      const appointmentCanCancel =
        moment(
          e.fecha + " " + Utils.convertirHora24a12(e.hora),
          "YYYY-MM-DD hh:mm a"
        ) > moment();
      return {
        ...e,
        canCancel: appointmentCanCancel,
        newFecha: Utils.formatDate(e.fecha),
        newHora: Utils.convertirHora24a12(e.hora),
      };
    });
  }

  async cancelAppointment(itemToCancel: any) {
    this.modalHeaderTemplate = `Confirmar cancelación de cita`;
    this.modalTextTemplate = `
    Confirma que desea cancelar la cita para el <b>${Utils.formatDate(
      itemToCancel.fecha
    )}</b> a las <b>${Utils.convertirHora24a12(itemToCancel.hora)}</b>?
    `;
    this.showConfirmCancel = true;
    this.appointmentToCancel = itemToCancel;
  }

  async confirmModal() {
    if (!this.appointmentToCancel) {
      return;
    }
    const result = await this.appointmentsService.cancelAppointment({
      identification: this.documentPatient,
      programId: this.appointmentToCancel.idPrograma,
      doctorCode: this.appointmentToCancel.codigoMedico,
      registerDate: Utils.formatOnlyDateForSchedule(
        this.appointmentToCancel.fecha
      ),
      registerHour: this.appointmentToCancel.hora,
    });
    if (result.length == 0) {
      this.showConfirmCancel = false;
      this.$store.dispatch("setAlert", {
        text: `No se completó el proceso de cancelación. Por favor verifique el estado de la cita`,
        type: "warning",
      });
      await this.getAppointments();
      return;
    }
    this.showConfirmCancel = false;

    this.showConfirmation(result[0].NuevoConsecutivo);
  }

  async closeModalConfirmation() {
    this.showConfirmCancel = false;
    this.showConfirmCancel2 = false;
    await this.getAppointments();
    this.modalHeaderTemplate = ``;
    this.modalTextTemplate = ``;
  }

  showConfirmation(consecutivo: any) {
    this.modalHeaderTemplate = `Confirmación de Cancelación`;
    this.modalTextTemplate = `Cita cancelada correctamente con consecutivo # ${consecutivo}`;
    this.showConfirmCancel2 = true;
  }
}
