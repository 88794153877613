import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  name: "",
  components: {},
  computed: {},
})
export default class ModalTemplate extends Vue {
  @Prop({ default: false }) showDialog!: boolean;
  @Prop() private modalTitle!: string;
  @Prop() private modalText!: string;
  @Prop({ default: "" }) modalTextCancel!: string;
  @Prop({ default: "" }) modalTextAccept!: string;
  @Prop({ default: false }) onlyAccept!: boolean;
  @Prop({ default: false }) onlyCancel!: boolean;

  get CANCEL_BTN() {
    return this.modalTextCancel ? this.modalTextCancel : "Cancelar";
  }

  get ACCEPT_BTN() {
    return this.modalTextAccept ? this.modalTextAccept : "Aceptar";
  }

  private accept() {
    this.$emit("modalAccept");
  }

  private cancel() {
    this.$emit("modalCancel");
  }
}
