import { Utils } from "@/shared/utils/Utils";
import Vue from "vue";
import { Component, Inject, Watch } from "vue-property-decorator";
import { MastersApiService } from "@/shared/services/mastersApi.service";

@Component({
  name: "Tarifas",
  components: {},
  computed: {},
  filters: {
    currency(value: any) {
      if (!value) return "";
      const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
  },
})
export default class Tarifas extends Vue {
  @Inject() mastersApiService!: MastersApiService;

  customerSelected = "";
  serviceSelected = null;
  procedureSelected = null;

  listServices = [];
  listCustomers = [];
  listProcedures = [];
  listProceduresFiltered: any = [];
  clients = ["9", "95", "5", "340", "30", "180", "140", "25", "100"];
  listPricesSelected: any = [];
  totalPrice = 0;

  filterTarifa = "";

  async mounted() {
    await this.getCustomers();
    await this.getServices();
  }

  @Watch("filterTarifa")
  onChangeFilterTarifa(value: any) {
    if (!value) {
      this.listProceduresFiltered = this.listProcedures;
      return;
    }
    const temp = this.listProcedures.filter((e: any) => {
      if (e.name.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }
      if (e.id.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }
      return false;
    });
    Vue.set(this, "listProceduresFiltered", temp);
  }

  @Watch("customerSelected")
  async onCustomerChange() {
    await this.getProcedures();
  }

  @Watch("serviceSelected")
  async onServiceChange() {
    await this.getProcedures();
  }

  async getServices() {
    this.listServices = await this.mastersApiService.getServices();
  }

  async getCustomers() {
    const tempo = await this.mastersApiService.getCustomers();
    this.listCustomers = tempo.filter((e: any) => this.clients.includes(e.id));
  }

  async getProcedures() {
    if (!this.serviceSelected) {
      return;
    }
    this.listProcedures = await this.mastersApiService.getProcedures(
      this.customerSelected,
      this.serviceSelected
    );
    let count = 0;
    this.listProceduresFiltered = this.listProcedures.map((e: any) => {
      count++;
      return {
        ...e,
        key: count,
      };
    });
  }

  async addPrice(item: any) {
    const found = this.listPricesSelected.find((e: any) => e.id == item.id);
    if (found) {
      return;
    }
    this.listPricesSelected.push(item);
    await this.calculatePrice();
  }

  async removePrice(itemId: any) {
    this.listPricesSelected = this.listPricesSelected.filter(
      (e: any) => e.id !== itemId
    );
    await this.calculatePrice();
  }

  async calculatePrice() {
    this.totalPrice = this.listPricesSelected.reduce(
      (acumulador: any, objeto: any) => acumulador + objeto.tarifa,
      0
    );
  }

  resetPrices() {
    this.listPricesSelected = [];
    this.calculatePrice();
  }
}
