/* eslint-disable */
import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";
import { AuthService } from "./services/AuthService";

@Component({
  name: "Login",
  components: {},
  computed: {},
})
export default class Login extends Vue {
  @Inject() authService!: AuthService;

  validForm = false;
  data = {
    user: "",
    password: "",
  };
  // emailRules = [
  //   (v: any) => !!v || "E-mail is required",
  //   (v: any) => /.+@.+\..+/.test(v) || "E-mail must be valid",
  // ];
  passwordRules = [(v: any) => !!v || "Password is required"];

  async signIn() {
    const isValid = this.validate();
    if (!isValid) {
      return;
    }
    // this.authService
    //   .signInByEmailAndPassword(this.data.user, this.data.password)
    //   .then(() => {
    //     this.$router.push("/home");
    //   })
    //   .catch((error) => {
    //     console.log("toast", error);
    //   });
    this.authService
      .signWithSolin(this.data.user, this.data.password)
      .then(() => {
        this.$router.push("/home");
      })
      .catch((error) => {
        this.$store.dispatch("setAlert", {
          text: "Las credenciales no son válidas",
          type: "error",
        });
      });
  }

  validate() {
    const form = this.$refs.formLogin as Vue & { validate: () => boolean };
    return form?.validate();
  }
}
