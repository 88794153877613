import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
moment.locale("es");

export class Utils {
  // TODO: Validar tipo
  // eslint-disable-next-line
  static formatDateCalendar(date: any) {
    moment.locale("es");
    return moment(date).format("DD [de] MMMM [de] YYYY, hh:mm:ss A");
  }

  static formatDateDD_MMMM_YYYY(date: any) {
    moment.locale("es");
    return moment(date).format("DD [de] MMMM [de] YYYY");
  }

  static formatOnlyDateForSchedule(date: any) {
    moment.locale("es");
    const newDate = typeof date == "string" ? date.split("T")[0] : date;
    return moment(newDate).format("YYYYMMDD");
  }

  static formatOnlyHourForSchedule(date: any) {
    moment.locale("es");
    return moment(date).format("HH:mm A");
  }

  static formatDate(date: any) {
    moment.locale("es");
    return moment(date.split("T")[0]).format("DD [de] MMMM [de] YYYY");
  }

  static convertirHora24a12(hora24: string) {
    const [horas, minutos] = hora24.split(":");
    let newHoras = parseInt(horas);
    const newMinutos = minutos.split(" ");

    // const periodo = newHoras > 12 ? "PM" : "AM";
    if (newHoras > 12) {
      newHoras = newHoras - 12;
    }

    return `${newHoras}:${newMinutos[0]} ${newMinutos[1]}`;
  }

  static todayFormated() {
    moment.locale("es");
    return moment().format("YYYY-MM-DD");
  }
}
